import React, { useEffect, useState } from "react";
import {
	StyleSheet,
	View,
	TouchableOpacity,
	ActivityIndicator,
	Dimensions
} from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { EventsTab } from "./EventsTab";
import { DonationsTab } from "./DonationsTab";
import { MembersTab } from "./MembersTab";
import { InspiringTab } from "./InspiringTab";
import { useDashboard } from "context/DashboardContext";
import { useAuth } from "context/AuthContext";
import { dollarFormatter } from "utils/helpers";
import { useRayze } from "context/RayzeContext";
import { Tabs } from "components/Tabs/Tabs";
import { useOrganization } from "context/OrganizationContext";
import useMediaQuery from "hooks/useMediaQuery";

const { height } = Dimensions.get("window");

export function Dashboard() {
	const { currentUser } = useAuth();
	const { selectedOrg } = useRayze();
	const { rayzeStats } = useDashboard();
	const { orgs = [], setGetOrgs } = useOrganization();
	const { getResponsiveValue } = useMediaQuery();
	const styles = getStyles({ getResponsiveValue });

	useEffect(() => {
		setGetOrgs(true);
	}, []);

	let {
		totalDonationsCount = 0,
		totalDonations = 0,
		totalComments = 0,
		totalUsers = 0,
		activeUsers = 0,
		totalNonprofits = 0,
		activeNonprofits = 0,
		totalBusinesses = 0,
		activeBusinesses = 0,
		totalNonprofitsAcceptingDonations = 0,
		totalPosts = 0,
		totalPhotosUploaded = 0,
		totalVideosUploaded = 0,
		totalLumens = 0,
		totalInspirations = 0
	} = rayzeStats;

	if (orgs?.length) {
		totalNonprofitsAcceptingDonations = orgs.filter(
			(org) => org.stripeComplete && org.verified
		)?.length;
		totalNonprofits = orgs.length;
	}

	const tabs = ["Events", "Recent Donations"];

	const [activeTab, setActiveTab] = useState(tabs[0]);

	const statistics = [
		{ title: "Active Users", value: activeUsers },
		{ title: "Total Donations", value: totalDonationsCount },
		{
			title: "Processed Donations",
			value: dollarFormatter.format(totalDonations)
		}
		// { title: "Platform Fee Collected", value: "##" },
		// { title: "Registered Users", value: totalUsers },

		// { title: "Community Groups", value: rayzeStats?.totalCommunityGroups },
	];

	const info = [
		{ title: "Active Nonprofits", value: activeNonprofits },
		{
			title: "Nonprofits Accepting Donations",
			value: totalNonprofitsAcceptingDonations
		},
		{ title: "Active Businesses", value: activeBusinesses },
		{ title: "Inspirations", value: totalInspirations },
		{ title: "Deleted Accounts", value: totalUsers - activeUsers }
	];
	const info2 = [
		{ title: "Posts", value: totalPosts },
		{ title: "Photos Uploaded", value: totalPhotosUploaded },
		{ title: "Videos Uploaded", value: totalVideosUploaded },
		{ title: "Lumens", value: totalLumens },
		{ title: "Comments", value: totalComments }

		// { title: "Reported Users", value: "##" },
		// { title: "Blocked Users", value: "##" },
		// { title: "Events posted", value: "##" },
		// { title: "Total check-ins", value: "##" },
	];

	return (
		<>
			{!selectedOrg ? (
				<View style={{ flexGrow: 1, height: height - 100 }}>
					<ActivityIndicator
						animating
						size={"large"}
						style={styles.activity}
						color={COLORS.primary.lightBlue}
					/>
				</View>
			) : (
				<View style={styles.container}>
					<PrimaryText
						fontSize={24}
						fontWeight={700}
						style={styles.header}
					>
						{`Welcome to your Rayze Dashboard, ${currentUser?.firstName}`}
					</PrimaryText>
					<View style={[styles.info, { marginTop: 24 }]}>
						<View style={styles.stats}>
							{statistics.map((item, i) => {
								const { title, value } = item;
								return (
									<View
										style={styles.statContainer}
										key={i}
									>
										<PrimaryText
											fontSize={18}
											fontWeight={400}
											style={styles.header}
										>
											{title}
										</PrimaryText>
										<PrimaryText
											fontSize={24}
											fontWeight={700}
											style={styles.header}
										>
											{value}
										</PrimaryText>
									</View>
								);
							})}
						</View>
					</View>

					<View style={styles.info}>
						<View style={[styles.infoContainer]}>
							{info.map((u, i) => {
								const { title, value } = u;
								// if (!value) return
								return (
									<View
										style={styles.infoTextRow}
										key={i}
									>
										<PrimaryText
											fontSize={14}
											fontWeight={400}
											style={styles.infoText}
										>
											{title}
										</PrimaryText>

										<PrimaryText
											fontSize={16}
											fontWeight={600}
											style={u?.capitalize && { textTransform: "capitalize" }}
										>
											{value}
										</PrimaryText>
									</View>
								);
							})}
						</View>

						<View style={[styles.infoContainer]}>
							{info2.map((u, i) => {
								const { title, value } = u;
								// if (!value) return
								return (
									<View
										style={styles.infoTextRow}
										key={i}
									>
										<PrimaryText
											fontSize={14}
											fontWeight={400}
											style={styles.infoText}
										>
											{title}
										</PrimaryText>

										<PrimaryText
											fontSize={16}
											fontWeight={600}
											style={u?.capitalize && { textTransform: "capitalize" }}
										>
											{value}
										</PrimaryText>
									</View>
								);
							})}
						</View>
					</View>

					<Tabs
						tabs={tabs}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/>

					{activeTab === tabs[0] ? (
						<EventsTab />
					) : activeTab === tabs[1] ? (
						<DonationsTab />
					) : activeTab === "Team Members" ? (
						<MembersTab />
					) : activeTab === "Inspiring" ? (
						<InspiringTab />
					) : null}
				</View>
			)}
		</>
	);
}

const getStyles = ({ getResponsiveValue }) =>
	StyleSheet.create({
		activity: {
			flex: 1,
			width: "100%",
			backgroundColor: COLORS.primary.white
		},
		container: {
			flex: 1,
			width: "100%",
			paddingTop: 20,
			backgroundColor: COLORS.primary.white
		},
		header: {
			color: COLORS.primary.darkBlue
		},
		cover_img_v: {
			width: "100%",
			aspectRatio: 16 / 9
		},
		statsPhoto: {
			width: "35%"
		},
		stats: {
			flex: 1,
			flexDirection: getResponsiveValue({ xs: "column", md: "row" }),
			padding: 27,
			justifyContent: "space-around",
			backgroundColor: COLORS.primary.offWhite,
			borderRadius: 12,
			gap: 12
		},
		statContainer: {
			alignItems: "center"
		},
		info: {
			flexDirection: getResponsiveValue({ xs: "column", md: "row" }),
			marginBottom: 16,
			gap: 6
		},
		infoContainer: {
			flex: 1,
			padding: 20,
			justifyContent: "space-around",
			backgroundColor: COLORS.primary.offWhite,
			borderRadius: 12
		},
		infoTextRow: {
			flexDirection: "row",
			alignItems: "center",
			marginVertical: 6
		},
		infoText: {
			flex: 1
		},
		causesContainer: {
			flex: 1,
			// height: 200,
			padding: 20,
			backgroundColor: COLORS.primary.offWhite,
			borderRadius: 12,
			marginLeft: 16
		},
		causes: {
			flex: 1,
			flexDirection: "row",
			flexWrap: "wrap",
			paddingBottom: 20,
			paddingTop: 12
		},
		cause: {
			flexDirection: "row",
			alignItems: "center",
			paddingVertical: 4,
			paddingHorizontal: 8,
			marginRight: 8,
			marginBottom: 10,
			borderRadius: 4,
			borderWidth: 1,
			borderColor: COLORS.primary.lightBlue
		},
		tabs: {
			flexDirection: "row",
			alignItems: "flex-start"
		},
		tabsButton: {
			width: 168,
			height: 36,
			backgroundColor: COLORS.primary.gray,
			borderTopLeftRadius: 12,
			borderTopRightRadius: 12,
			marginRight: 12,
			alignItems: "center",
			justifyContent: "center"
		},
		tabActive: {
			backgroundColor: COLORS.primary.offWhite,
			color: "black"
		},
		tabText: {
			textAlign: "center",
			color: COLORS.primary.white,
			fontWeight: "500"
		},
		tabTextActive: {
			color: COLORS.primary.darkBlue,
			fontWeight: "700"
		}
	});
