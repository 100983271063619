export const COLORS = {
	primary: {
		lightBlue: "#1DA1F2",
		blue: "rgba(92, 137, 211, 1)",
		teal: "rgba(93,174,212,1)",
		darkBlue: "#001935",
		white: "#FFF",
		black: "#000",
		error: "#EE1D52",
		gray: "#999999",
		lightGray: "#C4C4C4",
		darkGray: "#444444",
		sunflower: "#FFE000",
		orange: "#FEAB09",
		lightOrange: "#F9CF93",
		green: "#B8D35E",
		offWhite: "#F6F6F6",
		neutral_400: "#C4C4C4"
	},
	secondary: {
		offWhite: "#F6F6F6",
		opaqueWhite: "rgba(255,255,255,0.95)",
		gray: "#828282"
	},
	background: {
		darkBlue: "#001935",
		lightBlue: "#1DA1F2",
		darkOrange: "#FEAB09",
		white: "#ffffff",
		offWhite: "rgba(255,255,255,0.95)",
		lightRed: "#FEEFEF"
	},
	text: {
		white: "#ffffff",
		lightBlue: "#1DA1F2",
		gray: "#828282",
		lightGray: "#C4C4C4",
		darkGrey: "#444444",
		error: "#EE1D52"
	}
};
