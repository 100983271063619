import { firestore, auth, storage } from "utils/firebase";
import { getUsersName, unique } from "utils/helpers";

// const baseURL = 'http://localhost:8080'
const baseURL = "https://api.rayzeapp.com";

export const listenToUser = (currentAuth, setCurrentUser, onError) => {
	return firestore
		.collection("users")
		.doc(currentAuth.uid)
		.onSnapshot(
			(documentSnapshot) => {
				//check if user active here
				if (documentSnapshot.exists) {
					let user = {
						id: currentAuth.uid,
						...documentSnapshot.data()
					};

					setCurrentUser(user);
				} else {
					onError();
				}
			},
			(err) => {
				console.log("listenToUser Error", currentAuth?.uid, err);
			}
		);
};

export const createUser = (data, profilePic, setOnboarding) => {
	const uid = auth().currentUser.uid;
	data.createdAt = new Date();
	data.lastUpdated = new Date();
	data.followers = 0;
	data.following = 0;
	firestore
		.collection("users")
		.doc(uid)
		.set(data)
		.then(() => {
			profilePic && uploadProfilePicture(profilePic, setOnboarding);
			// setOnboarding(false)
		})
		.catch((e) => {
			console.log("api createUser error", e);
		});
};

//add in profile pic promise
export const getUser = async (userId, setCurrentUser = null) => {
	firestore
		.collection("users")
		.doc(userId)
		.get()
		.then((documentSnapshot) => {
			//check if user active here
			if (documentSnapshot.exists) {
				let user = {
					id: userId,
					...documentSnapshot.data()
				};
				if (setCurrentUser) setCurrentUser(user);
			} else {
				console.log("authApi getUser doesnt exist", userId);
				if (setCurrentUser) setCurrentUser(null);
			}
		});
};

export const uploadProfilePicture = (image, setLoading) => {
	const uid = auth().currentUser.uid;
	let profilePic = storage.ref().child("profilePictures/" + uid + ".jpg");

	var metadata = {
		cacheControl: "max-age=31536000"
	};

	profilePic
		.putFile(image, metadata)
		.then((snapshot) => {
			setLoading(false);
		})
		.catch((error) => {
			console.log("utils uploadProfilePicture error ", error);
			// setError();
		});
};

/** USER SPECIFIC */

export const updateUser = async (userData, saveSuccessful) => {
	const uid = auth().currentUser.uid;
	firestore
		.collection("users")
		.doc(uid)
		.update(userData)
		.then(() => {
			saveSuccessful && saveSuccessful();
		})
		.catch((e) => {
			console.log("api updateUser error: ", e);
		});
};

export const checkFollowStatus = async (userId, setFollowing) => {
	const uid = auth().currentUser.uid;
	firestore
		.collection("user_following")
		.doc(`${uid}-${userId}`)
		.get()
		.then((doc) => {
			setFollowing(doc.exists && doc.data().active);
		});
};

export const updateFollowStatus = async (
	userId,
	currentUser,
	following,
	setFollowing
) => {
	const uid = auth().currentUser.uid;
	firestore
		.collection("user_following")
		.doc(`${uid}-${userId}`)
		.set({
			active: !following,
			follower: uid,
			following: userId,
			lastUpdated: new Date(),
			sendersName: getUsersName(currentUser),
			lowercaseName: getUsersName(currentUser).toLowerCase()
		})
		.then((doc) => {
			setFollowing(!following);
		});
};

export const getActionAttendees = async (searchText, setUsers, attendees) => {
	let allAttendees = [];
	attendees.forEach((val, index) => {
		firestore
			.collection("users")
			.doc(val)
			.get()
			.then((doc) => {
				let user = {
					...doc.data(),
					id: doc.id
				};
				allAttendees.push(user);
				if (index == attendees.length - 1) {
					setUsers(allAttendees);
				}
			});
	});
};

export const getFollowers = async (searchText, setUsers, id) => {
	const uid = id ? id : auth().currentUser.uid;
	const search = searchText.toLowerCase();
	let query = firestore
		.collection("user_following")
		.where("following", "==", uid)
		.where("lowercaseName", ">=", search)
		.where("lowercaseName", "<=", search + "\uf8ff")
		.limit(20);
	if (search === "")
		query = firestore
			.collection("user_following")
			.where("following", "==", uid)
			.limit(20);

	query.get().then((querySnapshot) => {
		let promises = querySnapshot.docs.map((item, index) =>
			getUsersPromise(item.data().follower)
		);
		Promise.all(promises).then((users) => {
			const filteredDeleted = users.filter((x) => x !== null);
			setUsers(filteredDeleted);
		});
	});
};

export const getFollowing = async (searchText, setUsers, id) => {
	const uid = id ? id : auth().currentUser.uid;

	const search = searchText.toLowerCase();

	let query = firestore
		.collection("user_following")
		.where("following", "==", uid)
		.where("lowercaseName", ">=", search)
		.where("lowercaseName", "<=", search + "\uf8ff")
		.limit(20);
	if (search === "")
		query = firestore
			.collection("user_following")
			.where("follower", "==", uid)
			.limit(20);

	query.get().then((querySnapshot) => {
		let promises = querySnapshot.docs.map((item, index) =>
			getUsersPromise(item.data().following)
		);
		Promise.all(promises).then((users) => {
			const followers = users.filter((x) => x !== null);
			setUsers(followers);
		});
	});
};

export const getUsersPromise = (id) => {
	return new Promise((resolve, reject) => {
		firestore
			.collection("users")
			.doc(id)
			.get()
			.then((doc) => {
				//check if user active here
				if (doc.exists) {
					let user = {
						id: doc.id,
						...doc.data()
					};
					resolve(user);
				} else {
					resolve(null);
				}
			});
	});
};

export const getUsersFromMembersPromise = (userId, obj) => {
	return new Promise((resolve, reject) => {
		firestore
			.collection("users")
			.doc(userId)
			.get()
			.then((doc) => {
				//check if user active here
				if (doc.exists) {
					const data = doc.data();
					const birthday = data.birthday.toDate();
					data.birthday = birthday;
					let user = {
						...obj,
						...data
					};
					resolve(user);
				} else {
					resolve(null);
				}
			});
	});
};

export const getUserEmail = (uid, setEmail) => {
	const url = baseURL + "/getUserEmail/" + uid;

	const requestOptions = {
		method: "GET"
	};

	//Part is going TO a client so the client needs billed

	fetch(url, requestOptions)
		.then((response) => response.json())
		.then((data) => {
			// console.log('result getUserEmail',data);
			setEmail(data?.email);
		})
		.catch((e) => {
			console.log("error getUserEmail", e);
		});
};

export const updateMemberInfo = (org, user, data, onSuccess) => {
	firestore
		.collection("organizations")
		.doc(org.id)
		.collection("members")
		.doc(user.id)
		.update(data)
		.then(() => {
			onSuccess();
		})
		.catch((e) => {
			console.log("error updating member", e);
		});
};

export const getProfilePicture = async (uid, setProfilePic) => {
	const profilePic = storage.ref().child("profilePictures/" + uid + ".jpg");
	profilePic
		.getDownloadURL()
		.then((url) => {
			setProfilePic(url);
		})
		.catch((err) => {
			setProfilePic(null);
		});
};

export const fetchMoreUsers = async (
	lastDoc,
	setLastDoc,
	users,
	setUsers,
	sorted
) => {
	let userRef = firestore
		.collection("users")
		.orderBy(sorted?.sortBy || "username", sorted?.sortOrder || "asc")
		.limit(10);

	let tempUsers = [...users];
	if (lastDoc) {
		userRef = firestore
			.collection("users")
			.orderBy(sorted?.sortBy || "username", sorted?.sortOrder || "asc")
			.startAfter(lastDoc.createdAt)
			.limit(10);
	}
	const usersSnap = await userRef.get().catch((e) => {
		console.log("fetchMoreUsers err", e);
	});

	console.log(usersSnap.forEach((item) => console.log(item.data())));

	usersSnap.docs.map(async (item, index) => {
		tempUsers.push({
			...item.data(),
			id: item.id
		});
	});
	setUsers(tempUsers);
	setLastDoc(tempUsers[tempUsers?.length - 1]);
};

export const deleteUser = (user, onSuccess) => {
	firestore
		.collection("organizations")
		.doc(user?.id)
		.delete()
		.then(() => {
			onSuccess();
		})
		.catch((e) => {
			console.log("error deleting user", e);
		});
};

export const searchUsers = (searchText, setResults) => {
	const search = searchText.toLowerCase().replace(/[^a-zA-Z ]/g, "");
	if (!search) return;

	let userNameQuery = firestore
		.collection("users")
		.where("lowercaseName", ">=", search)
		.where("lowercaseName", "<=", search + "\uf8ff")
		.limit(20);

	let userUsernameQuery = firestore
		.collection("users")
		.where("username", ">=", search)
		.where("username", "<=", search + "\uf8ff")
		.limit(20);

	let promises = [userNameQuery.get(), userUsernameQuery.get()];

	Promise.all(promises)
		.then((snapshots) => {
			const temp = [];
			for (const snap of snapshots) {
				for (const doc of snap.docs) {
					const data = doc.data();
					const result = {
						id: doc.id,
						...data
					};
					temp.push(result);
				}
			}
			let uniq = unique(temp);
			setResults(uniq);
		})
		.catch((e) => {
			console.log("error getting user search", e);
		});
};
