import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import useMediaQuery from "hooks/useMediaQuery";

export function Tabs(props) {
	const { getResponsiveValue } = useMediaQuery();
	const { tabs, activeTab, setActiveTab } = props;
	const styles = getStyles(getResponsiveValue);
	return (
		<View style={styles.tabs}>
			{tabs.map((t, i) => {
				return (
					<TouchableOpacity
						key={i}
						style={[styles.tabsButton, t === activeTab && styles.tabActive]}
						onPress={() => setActiveTab(t)}
					>
						<PrimaryText
							fontSize={14}
							fontWeight={700}
							style={[styles.tabText, t === activeTab && styles.tabTextActive]}
						>
							{t}
						</PrimaryText>
					</TouchableOpacity>
				);
			})}
		</View>
	);
}

const getStyles = (getResponsiveValue) =>
	StyleSheet.create({
		tabs: {
			flexDirection: "row",
			alignItems: "flex-start",
			overflow: "auto"
		},
		tabsButton: {
			flex: getResponsiveValue({ xs: null, sm: 1 }),
			maxWidth: 220,
			paddingHorizontal: 12,
			height: 36,
			backgroundColor: COLORS.primary.offWhite,
			borderTopLeftRadius: 12,
			borderTopRightRadius: 12,
			marginRight: 12,
			alignItems: "center",
			justifyContent: "center"
		},
		tabActive: {
			backgroundColor: COLORS.primary.darkBlue
		},
		tabText: {
			textAlign: "center",
			color: COLORS.primary.darkBlue,
			fontWeight: "400",
			fontSize: 13
		},
		tabTextActive: {
			color: COLORS.primary.white,
			fontWeight: "800"
		}
	});
