import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { TableCell, TableRow } from "@mui/material";
import { getUser } from "services/user-service";
import { getUsersGender, getUsersName } from "utils/helpers";

export const UserCard = ({ user, onPress }) => {
	// const { userId, amount, date, recurring } = item

	// const [user, setUser] = useState({})

	// useEffect(() => {
	//   getUser(userId, setUser)
	// }, [userId])

	return (
		<TableRow
			hover
			onClick={onPress}
		>
			<TableCell>
				<ProfilePic
					uri={user?.profilePic}
					size={36}
				/>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
				>
					{getUsersName(user)}
				</PrimaryText>
			</TableCell>
			{/* <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {recurring ? "recurring" : "one-time"}
        </PrimaryText>
      </TableCell> */}
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
				>
					{user?.city}
				</PrimaryText>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
				>
					{user?.state}
				</PrimaryText>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
				>
					{getUsersGender(user)}
				</PrimaryText>
			</TableCell>
			{/* <TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
				>
					{user?.state}
				</PrimaryText>
			</TableCell> */}
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
				>
					{user?.createdAt.toDate().toDateString()}
				</PrimaryText>
			</TableCell>
		</TableRow>
	);
};
