import React, { useState, useEffect, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { useDashboard } from "context/DashboardContext";
import { useNavigate } from "react-router";
import { Links } from "constants/Links";
import * as FaIcons from "react-icons/fa";
import { TouchableOpacity } from "react-native-web";
import { useCRM } from "context/CRMContext";
import { CRMCard } from "components/Cards/CRMCard";
import {
	getFilteredContacts,
	saveContact,
	searchContacts
} from "services/crm-service";
import { CustomInput } from "components/Inputs/CustomInput";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { useRayze } from "context/RayzeContext";
import { ContactForm } from "components/CRM/ContactForm";
import { useAuth } from "context/AuthContext";
import { ControlledMenu, MenuDivider } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { getUsersName } from "utils/helpers";

const pageSize = 10;
const dateOptions = [
	{ value: "all", label: "All" },
	{ value: "thisWeek", label: "This Week" },
	{ value: "lastWeek", label: "Last 2 Weeks" }
];

const statusOptions = [
	{ value: null, label: "Any" },
	{ value: 3, label: "Joined" },
	{ value: 2, label: "In Progress" }
];

export const CRM = () => {
	const navigate = useNavigate();
	const { rayzeStats } = useDashboard();
	const { currentUser } = useAuth();
	const {
		contacts: contextContacts = [],
		setGetContacts,
		setSelectedContact,
		nextPage,
		page,
		setPage,
		newContact,
		setNewContact,
		linkedOrg,
		setLinkedOrg
	} = useCRM();
	const { team } = useRayze();

	const [contacts, setContacts] = useState(contextContacts);
	const [search, setSearch] = useState("");

	const [showNewContactModal, setShowNewContactModal] = useState(false);

	//filter
	const [open, setOpen] = useState(false);
	const [filter, setFilter] = useState({
		date: "all",
		lead: null,
		status: null
	});
	const [filtering, setFiltering] = useState(false);
	const filterRef = useRef(null);

	useEffect(() => {
		setSelectedContact(null);
		setLinkedOrg(null);
		setGetContacts(true);
	}, []);

	useEffect(() => {
		if (search && !(filter.date !== "all" || filter.lead || filter.status)) {
			setFiltering(false);
			searchContacts(search, setContacts);
		} else if (filter.date !== "all" || filter.lead || filter.status) {
			setFiltering(true);
			getFilteredContacts(filter, gotFilteredContacts);
		} else {
			setFiltering(false);
			setContacts(contextContacts);
		}
	}, [search, contextContacts, filter]);

	useEffect(() => {
		setPage(1);
	}, [search, filter]);

	function gotFilteredContacts(contacts) {
		if (search) {
			const lowercaseSearch = search.toLowerCase();
			const filteredContacts = contacts.filter((x) => {
				return (
					x.lowercaseName.includes(lowercaseSearch) ||
					x.address?.state.toLowerCase().includes(lowercaseSearch) ||
					x.address?.city.toLowerCase().includes(lowercaseSearch) ||
					x.address?.zipCode.toLowerCase().includes(lowercaseSearch)
				);
			});
			setContacts(filteredContacts);
		} else {
			setContacts(contacts);
		}
	}

  //autogenerate info for linked orgs
  useEffect(() => {
    if (!linkedOrg) return;
    const {
      name,
      website,
      stripeID = "",
      profilePic = "",
      causes = [],
      address = {},
      mailingAddress = "",
      verified = false,
    } = linkedOrg;
    const updatedContact = {
      ...newContact,
      name,
      status: stripeID ? 4 : verified ? 5 : 3,
      website: website ? website : newContact?.website,
      profilePic,
      causes,
      address,
      mailingAddress,
    };
    setNewContact(updatedContact);
  }, [linkedOrg]);

	function onContactSelected(contact) {
		setSelectedContact(contact);
		navigate(Links.CRMDetail);
	}

	function onContactEdit(contact) {
		setSelectedContact(contact);
		setNewContact({ ...contact });
		setShowNewContactModal(true);
	}

	const currentTableData = () => {
		const firstPageIndex = (page - 1) * pageSize;
		const lastPageIndex = firstPageIndex + pageSize;
		return contacts.slice(firstPageIndex, lastPageIndex);
	};

  function saveNewContact() {
    saveContact(newContact, (contact) => {
      setLinkedOrg(null);
      let find = contacts.findIndex((x) => x.id === contact?.id);
      if (find > -1) {
        contacts[find] = { ...contact };
      } else {
        contacts.push(contact);
      }

			setShowNewContactModal(false);
			setNewContact({ status: 1, leadOwner: currentUser?.id });
		});
	}

	const totalCount =
		search || filtering ? contacts?.length : rayzeStats?.totalContacts;
	const totalPageCount = Math.ceil(totalCount / pageSize);
	const startCount = (page - 1) * pageSize + 1;
	const endCount = startCount + currentTableData()?.length - 1;

	const teamOptions = useMemo(() => {
		let temp = [{ value: null, label: "All" }];
		const mappedTeam = team?.map((x) => {
			return { value: x.id, label: getUsersName(x) };
		});
		return temp.concat(mappedTeam);
	}, [team]);

	return (
		<View style={styles.tableContainer}>
			<Box
				display="flex"
				flexDirection="row"
				width="100%"
				alignItems="center"
				justifyContent="space-between"
				marginBottom={24}
			>
				<Box
					display="flex"
					flexDirection={{ xs: "column", sm: "row" }}
					alignItems={{ xs: "flex-start", sm: "center" }}
					width="100%"
					gap={12}
				>
					<TouchableOpacity
						style={styles.button}
						onPress={() => {
							setNewContact({ status: 1, leadOwner: currentUser?.id });
							setShowNewContactModal(true);
						}}
					>
						<PrimaryText
							fontSize={14}
							fontWeight={800}
							style={{ color: COLORS.primary.white, marginRight: 12 }}
						>
							New Contact
						</PrimaryText>
						<FaIcons.FaPlus
							size={18}
							color={COLORS.primary.white}
						/>
					</TouchableOpacity>

					<CustomInput
						placeholder={"Search"}
						style={{ width: "100%", maxWidth: 400 }}
						onChangeText={setSearch}
						value={search}
					/>
				</Box>
				<View
					style={{
						flexDirection: "row",
						alignItems: "center"
					}}
				>
					<button
						ref={filterRef}
						style={{
							backgroundColor: "transparent",
							border: 0,
							cursor: "pointer"
						}}
						onClick={() => setOpen(!open)}
						onPointerEnter={() => setOpen(true)}
					>
						<FaIcons.FaEllipsisV
							size={24}
							color={COLORS.primary.darkBlue}
						/>
					</button>
					<ControlledMenu
						anchorRef={filterRef}
						state={open ? "open" : "closed"}
						transition
						onItemClick={(e) => (e.keepOpen = true)}
					>
						<View style={{ paddingHorizontal: 12 }}>
							<CustomPicker
								title={"Date Created"}
								value={filter.date}
								onChange={(value) => setFilter({ ...filter, date: value })}
								options={dateOptions}
								style={{ width: 200 }}
							/>
						</View>
						<MenuDivider />
						<View style={{ paddingHorizontal: 12 }}>
							<CustomPicker
								title="Lead Owner"
								value={filter.lead}
								onChange={(value) => setFilter({ ...filter, lead: value })}
								options={teamOptions}
								style={{
									width: 200,
									flexGrow: undefined,
									alignSelf: "flex-end"
								}}
							/>
						</View>
						<MenuDivider />
						<View style={{ paddingHorizontal: 12 }}>
							<CustomPicker
								title="Status"
								value={filter.status}
								onChange={(value) => setFilter({ ...filter, status: value })}
								options={statusOptions}
								style={{
									width: 200,
									flexGrow: undefined,
									alignSelf: "flex-end"
								}}
							/>
						</View>
					</ControlledMenu>
				</View>
			</Box>

			<TableContainer>
				<Table
					className="table"
					style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
				>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell>Organization</TableCell>
							<TableCell>Lead Owner</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Est. Revenue</TableCell>
							<TableCell># of Employees</TableCell>
							<TableCell>Edit</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{currentTableData() &&
							currentTableData().map((item, i) => {
								return (
									<CRMCard
										item={item}
										key={i}
										onPress={() => onContactSelected(item)}
										onEdit={() => onContactEdit(item)}
										team={team}
									/>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<View
				style={{ flexDirection: "row", alignSelf: "flex-end", marginTop: 12 }}
			>
				<TouchableOpacity onPress={() => (page > 1 ? setPage(page - 1) : null)}>
					<FaIcons.FaChevronLeft
						size={20}
						color={COLORS.primary.darkGray}
					/>
				</TouchableOpacity>

				<PrimaryText
					fontSize={14}
					fontWeight={400}
					style={styles.subtitle}
				>
					{`${startCount} - ${endCount} of ${totalCount}`}
				</PrimaryText>

				<TouchableOpacity
					onPress={() => (page < totalPageCount ? nextPage() : null)}
				>
					<FaIcons.FaChevronRight
						size={20}
						color={COLORS.primary.darkGray}
					/>
				</TouchableOpacity>
			</View>

			{/** NEW CONTACT */}
			<ContactForm
				title={newContact?.id ? "EDIT CONTACT" : "New Contact"}
				contact={newContact}
				setContact={setNewContact}
				showModal={showNewContactModal}
				setShowModal={setShowNewContactModal}
				onSave={saveNewContact}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	tableContainer: {
		width: "100%",
		// backgroundColor: COLORS.primary.offWhite,
		borderRadius: 12,
		borderTopLeftRadius: 0,
		paddingVertical: 12
	},
	header: {
		color: COLORS.primary.darkBlue
	},
	subtitle: {
		color: COLORS.primary.darkBlue,
		marginHorizontal: 24
	},
	button: {
		flexDirection: "row",
		backgroundColor: COLORS.primary.lightBlue,
		width: 200,
		justifyContent: "center",
		alignItems: "center",
		padding: 8,
		borderRadius: 40
	},
	button2: {
		flexDirection: "row",
		backgroundColor: COLORS.primary.lightBlue,
		// width: 100,
		justifyContent: "center",
		alignItems: "center",
		alignSelf: "center",
		padding: 8,
		margin: 12,
		borderRadius: 40
	},
	settingsRow: {
		flexDirection: "row",
		paddingHorizontal: 13,
		paddingVertical: 8,
		alignItems: "center",
		justifyContent: "space-between"
		// borderBottomColor: COLORS.primary.lightGray,
		// borderBottomWidth: 0.5,
	},
	searchContainer: {
		marginHorizontal: 16,
		padding: 8,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84
	},
	searchedItem: {
		padding: 4
	},
	inputTitle: {
		paddingLeft: 10,
		marginBottom: 5,
		color: COLORS.primary.darkGray
	},
	selectedLinkedOrg: {
		flexDirection: "row",
		height: 30,
		borderRadius: 13,
		flexGrow: 1,
		borderWidth: 1,
		borderColor: COLORS.primary.lightGray,
		alignItems: "center",
		justifyContent: "space-between",
		paddingHorizontal: 12,
		paddingRight: 4,
		backgroundColor: COLORS.primary.offWhite
	},
	text: {
		fontSize: 14,
		color: COLORS.primary.darkGray,
		fontWeight: 400,
		fontFamily: "Montserrat"
	}
});
