import { firestore, functions, storage } from "utils/firebase";
import firebase from "firebase/compat/app";
import { getUsersFromMembersPromise, getUsersPromise } from "./user-service";
import { unique } from "utils/helpers";
import {
	getCountFromServer,
	collection,
	where,
	orderBy,
	query,
	startAfter,
	endBefore,
	limit,
	getDocs,
	limitToLast
} from "firebase/firestore";
import { set } from "lodash";

//need to move to promise for pics
export const getUserOrgs = (setSelectedOrg) => {
	return firestore
		.collection("organizations")
		.doc("yAy9tjWiEp7IKMRgQJFT")
		.onSnapshot(
			(doc) => {
				const id = doc.id;
				const docData = doc.data();
				const org = {
					id,
					...docData
				};

				setSelectedOrg(org);
			},
			(e) => {
				console.log("error getting user orgs listener", e);
			}
		);
};

export const listenToOrg = (org, setSelectedOrg) => {
	return firestore
		.collection("organizations")
		.doc(org?.id)
		.onSnapshot(
			(doc) => {
				const id = doc.id;
				const docData = doc.data();
				const org = {
					id,
					...docData
				};

				setSelectedOrg(org);
			},
			(e) => {
				console.log("error getting user orgs listener", e);
			}
		);
};

export const getOrg = async (orgId, setSelectedOrg) => {
	return firestore
		.collection("organizations")
		.doc(orgId)
		.get()
		.then((doc) => {
			if (!doc.exists) return;
			const id = doc.id;
			const docData = doc.data();
			const org = {
				id,
				...docData
			};

			setSelectedOrg(org);
		})
		.catch((e) => {
			console.log("error getting org", e);
		});
};

export const deleteOrg = (org, onSuccess) => {
	firestore
		.collection("organizations")
		.doc(org?.id)
		.delete()
		.then(() => {
			onSuccess();
		})
		.catch((e) => {
			console.log("error deleting org", e);
		});
};

export function getOrgCoverPhoto(orgId, setCoverPhoto) {
	const picRef = storage.ref(`orgCoverPhotos/${orgId}/0.jpg`);

	picRef
		.getDownloadURL()
		.then((uri) => {
			setCoverPhoto(uri);
		})
		.catch((err) => {});
}

export function getOrgPicturePromise(obj, orgId) {
	return new Promise((resolve, reject) => {
		const picRef = storage.ref(`orgCoverPhotos/${orgId}/0.jpg`);

		picRef
			.getDownloadURL()
			.then((uri) => {
				obj.coverPhoto = uri;
				resolve(obj);
			})
			.catch((err) => {
				resolve(obj);
			});
	});
}

export const createOrganization = (
	currentUser,
	organization,
	coverPhotos,
	onSuccess
) => {
	firestore
		.collection("organizations")
		.add(organization)
		.then((docRef) => {
			const newOrgAdd = firestore
				.collection("organizations")
				.doc(docRef.id)
				.collection("members");

			newOrgAdd
				.doc(currentUser.id)
				.set({
					role: "Leader",
					userId: currentUser.id,
					verified: true,
					publicPosts: true,
					postNewOpportunities: true,
					volunteerApplicationReview: true,
					volunteerCheckIn: true,
					viewDonations: true
				})
				.then(() => {
					coverPhotos.map((val, index) => {
						let ref = storage.ref(`orgCoverPhotos/${docRef.id}/${index}.jpg`);

						ref
							.put(val.blob, { cacheControl: "max-age=31536000" })
							.then((result) => {
								// console.log("result", result)
							})
							.catch((err) => {
								console.log("err", err);
							});
					});
				})
				.then(function () {
					console.log("Document Added ", docRef.id);
					if (onSuccess) onSuccess(docRef.id);
				})
				.catch(function (error) {
					console.error("Error adding document: ", error);
				});
		})
		.catch((error) => {
			console.error("Error adding document: ", error);
		});
};

export const updateOrganization = (
	org,
	data,
	coverPhotos,
	profilePics,
	onSuccess
) => {
	console.log("update org", org.id, data);
	const orgRef = firestore.collection("organizations").doc(org.id);

	orgRef
		.update(data)
		.then(() => {
			if (coverPhotos?.length === 0 && profilePics?.length === 0) {
				onSuccess(data);
				// Alert.alert("Success", "Update Successful", [
				//   { text: "Ok", onPress: () => onSuccess(data) },
				// ])
			} else {
				updateOrgPics(org.id, data, coverPhotos, profilePics, onSuccess);
			}
		})
		.catch((err) => {
			console.log("err", err);
			window.alert("Error", "An error has occured please try later.", [
				{ text: "Ok", onPress: onSuccess }
			]);
		});
};

const updateOrgPics = (orgId, data, coverPhotos, profilePics, onSuccess) => {
	const orgRef = firestore.collection("organizations").doc(orgId);
	let promises = [];
	coverPhotos.map((val, index) => {
		let ref = storage.ref(`orgCoverPhotos/${orgId}/${index}.jpg`);
		promises.push(
			ref
				.put(val.blob, { cacheControl: "max-age=31536000" })
				.then(async (result) => {
					console.log("result", result);
					const downloadURL = await ref.getDownloadURL();
					orgRef.update({ coverPhoto: downloadURL }).then(() => {
						data.coverPhoto = downloadURL;
					});
				})
				.catch((err) => {
					console.log("err", err);
				})
		);
	});
	profilePics.map((val, index) => {
		let ref = storage.ref(`orgProfilePics/${orgId}/${index}.jpg`);
		promises.push(
			ref
				.put(val.blob, { cacheControl: "max-age=31536000" })
				.then(async (result) => {
					console.log("profile pic upload result", result);
					const downloadURL = await ref.getDownloadURL();
					orgRef.update({ profilePic: downloadURL }).then(() => {
						data.profilePic = downloadURL;
					});
				})
				.catch((err) => {
					console.log("err", err);
				})
		);
	});
	console.log("test promises length", promises?.length);
	Promise.all(promises).then(() => {
		onSuccess(data);
		// Alert.alert("Success", "Update Successful", [
		//   { text: "Ok", onPress: () => onSuccess(data) },
		// ])
	});
};

export const getOrgMembers = (
	currentUser,
	organization,
	setMembers,
	setUserType,
	setCurrentMember
) => {
	return firestore
		.collection("organizations")
		.doc(organization.id)
		.collection("members")
		.onSnapshot(
			(membersQuerySnapshot) => {
				let membersData = [];

				membersQuerySnapshot.forEach((memberDoc) => {
					const memberData = memberDoc.data();
					const memberId = memberDoc.id;

					const member = {
						id: memberId,
						...memberData
					};

					membersData.push(member);
				});

				let promises = membersData.map((item, index) =>
					getUsersFromMembersPromise(item.id, item)
				);
				Promise.all(promises).then((users) => {
					const filteredDeleted = users.filter((x) => x !== null);
					const filteredMembers = filteredDeleted.filter(
						(x) => x.role !== "Sponsor"
					);
					setMembers(filteredMembers);

					const findUs = filteredMembers.find((x) => x.id === currentUser.id);
					if (findUs) {
						if (findUs.verified) {
							setCurrentMember(findUs);
							setUserType(findUs.role);
						}
					}
				});
			},
			(e) => {
				console.log("getOrgMembers error getting members of organization", e);
			}
		);
};

export const getOrgInvitedMembers = (organization, setInvitedMembers) => {
	return firestore
		.collection("inviteRequests")
		.where("orgId", "==", organization.id)
		.onSnapshot(
			(querySnapshot) => {
				let temp = [];
				querySnapshot.forEach((memberDoc) => {
					const data = memberDoc.data();
					const id = memberDoc.id;

					const invite = {
						id,
						...data,
						invite: true
					};

					temp.push(invite);
				});

				setInvitedMembers(temp);
			},
			(e) => {
				console.log("getOrgInvitedMembers error getting invites", e);
			}
		);
};

export const organizationAddNewUser = (
	organization,
	userId,
	role,
	isVerified
) => {
	try {
		firestore
			.collection("organizations")
			.doc(organization.id)
			.collection("members")
			.doc(userId)
			.set({
				role: role,
				verified: isVerified ? isVerified : false,
				publicPosts: false,
				postNewOpportunities: false,
				volunteerApplicationReview: false,
				volunteerCheckIn: false,
				viewDonations: false
			})
			.then(() => {
				firestore
					.collection("organizations")
					.doc(organization.id)
					.update({
						members: firebase.firestore.FieldValue.arrayUnion(userId)
					});
			})
			.catch((error) => {
				console.error("Error adding document: ", error);
			});
	} catch (err) {
		console.log(err);
	}
};

export const inviteMember = (org, role, email, onSuccess) => {
	firestore
		.collection("inviteRequests")
		.add({
			orgId: org.id,
			orgName: org.name,
			role,
			email
		})
		.then(() => {
			onSuccess();
		})
		.catch((e) => {
			console.log("error inviting member", e);
			window.alert("Error inviting member", e);
		});
};

export const archiveUser = (orgId, userId, active, invite, onSuccess) => {
	if (invite) {
		return firestore
			.collection("inviteRequests")
			.doc(userId)
			.delete()
			.then(() => {
				onSuccess();
			});
	}

	console.log("here");

	firestore
		.collection("organizations")
		.doc(orgId)
		.update({
			members: active
				? firebase.firestore.FieldValue.arrayUnion(userId)
				: firebase.firestore.FieldValue.arrayRemove(userId)
		})
		.then(() => {
			firestore
				.collection("organizations")
				.doc(orgId)
				.collection("members")
				.doc(userId)
				.update({
					active
				})
				.then(() => {
					onSuccess();
				})
				.catch((e) => {
					console.log("error updating member status", e);
				});
		})
		.catch((e) => {
			console.log("error updating members array", e);
		});
};

export const getOrgFollowers = async (
	organization,
	setFollowers,
	setLoading
) => {
	firestore
		.collection("org_following")
		.where("orgId", "==", organization.id)
		.get()
		.then((querySnapshot) => {
			let followers = [];
			querySnapshot.forEach((doc) => {
				const subDocData = doc.data();
				followers.push(subDocData.userId);
			});
			let promises = followers.map((item, index) => getUsersPromise(item));
			Promise.all(promises).then((users) => {
				const orgFollowers = users.filter((x) => x !== null);
				setFollowers(orgFollowers);
				setLoading(false);
			});
		});
};

export const getOrgProfilePicture = async (orgId, setProfilePic) => {
	const picRef = storage.ref(`orgProfilePics/${orgId}/0.jpg`);

	picRef
		.getDownloadURL()
		.then((url) => {
			setProfilePic(url);
		})
		.catch((err) => {
			setProfilePic(null);
		});
};

export const fetchMoreOrgs = async (
	lastDoc,
	setLastDoc,
	orgs,
	setOrgs,
	type,
	limit
) => {
	let orgRef = firestore
		.collection("organizations")
		.where("type", "==", type)
		.orderBy("name")
		.limit(limit || 10);

	let tempOrgs = [...orgs];

	if (lastDoc) {
		orgRef = firestore
			.collection("organizations")
			.where("type", "==", type)
			.orderBy("name")
			.startAfter(lastDoc.name)
			.limit(limit || 10);
	}
	const orgSnap = (await orgRef.get().catch((e) => {
		return console.log("fetchMoreOrgs err", e);
	})) || { docs: [] };

	orgSnap.docs.map(async (item, index) => {
		tempOrgs.push({
			...item.data(),
			id: item.id
		});
	});
	setOrgs(tempOrgs);
	setLastDoc(tempOrgs[tempOrgs?.length - 1]);
};

export const fetchAllNonprofits = async (setOrgs) => {
	let orgRef = firestore.collection("organizations").where('type', '==', 'nonprofit').orderBy("name");
	const orgSnap = await orgRef.get().catch((e) => {
		return console.log("fetchAllOrgs err", e);
	})
	setOrgs(orgSnap.docs.map((item) => ({ ...item.data(), id: item.id })));
};

export const fetchOrgs = async ({
	type, // nonprofit or business
	orgs = [], // array of orgnaizations
	totalOrgs = 0, // number of total organization rows
	currentPage = 1, // current page no.
	nextPage = 1, // next page no.
	sortBy = "name", // any key of org object
	orderIn = "asc", // asc or desc
	rowsPerPage = 10, // any number other than 0
	filter = "all" // any verified status
}) => {
	const coll = collection(firestore, "organizations");

	const queries = [];

	// Where to go next, previous, first or last
	const go =
		nextPage === 1
			? "first"
			: totalOrgs && nextPage === Math.ceil(totalOrgs / rowsPerPage)
			? "last"
			: nextPage > currentPage
			? "next"
			: nextPage < currentPage
			? "previous"
			: "first";

	// Define the rows limit
	const rowsLimit = go === "last" ? totalOrgs % rowsPerPage : rowsPerPage;

	// Where clauses for the conditions
	if (type) queries.push(where("type", "==", type));

	// Filter
	console.log('filters', filter)
	if (filter === "verified") {
		queries.push(where("verified", "==", true));
		queries.push(where("stripeComplete", "!=", true));
	}
	if (filter === "unverified") queries.push(where("verified", "==", false));
	if (filter === "acceptingDonations") queries.push(where("stripeComplete", "==", true));

	// Sorting
	queries.push(orderBy(sortBy || "name", orderIn || "asc"));

	// Get total organizations count before further query to prevent documents limitations
	const countQuery = query(coll, ...queries);
	const totalCount = await getCountFromServer(countQuery).catch((e) => {
		return console.log("fetchOrgs getCountFromServer err", e);
	})

	// Pagination queries
	if (go === "first") queries.push(limit(rowsLimit || 10));

	if (go === "last") queries.push(limitToLast(rowsLimit || 10));

	if (go === "next" && orgs.length)
		queries.push(
			startAfter(orgs[orgs.length - 1]?.[sortBy || "name"]),
			limit(rowsLimit || 10)
		);

	if (go === "previous" && orgs.length)
		queries.push(
			endBefore(orgs[0]?.[sortBy || "name"]),
			limitToLast(rowsLimit || 10)
		);

	// Get organizations data
	const dataQuery = query(coll, ...queries);
	const orgsSnap = await getDocs(dataQuery).catch((e) => {
		return console.log("fetchOrgs err", e);
	});

	if (!orgsSnap.size) throw new Error("No data found.");

	const newOrgs = orgsSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

	const result = {
		totalCount: totalCount.data().count,
		orgs: newOrgs
	};

	return result;
};

export const searchOrg = (searchText, setResults) => {
	const search = searchText.toLowerCase().replace(/[^a-zA-Z ]/g, "");

	let orgQuery = firestore
		.collection("organizations")
		.where("lowercaseName", ">=", search)
		.where("lowercaseName", "<=", search + "\uf8ff")
		.limit(20);

	let orgStateQuery = firestore
		.collection("organizations")
		.where("address.state", ">=", search)
		.where("address.state", "<=", search + "\uf8ff")
		.limit(20);

	let orgCityQuery = firestore
		.collection("organizations")
		.where("address.city", ">=", search)
		.where("address.city", "<=", search + "\uf8ff")
		.limit(20);

	let orgZipCodeQuery = firestore
		.collection("organizations")
		.where("address.zipCode", ">=", searchText)
		.where("address.zipCode", "<=", searchText + "\uf8ff")
		.limit(20);

	let promises = [];
	if (search) {
		promises = [orgQuery.get(), orgStateQuery.get(), orgCityQuery.get()];
	}
	if (searchText?.length === 5) {
		promises.push(orgZipCodeQuery.get());
	}

	Promise.all(promises)
		.then((snapshots) => {
			const temp = [];
			for (const snap of snapshots) {
				for (const doc of snap.docs) {
					const data = doc.data();
					const result = {
						id: doc.id,
						...data
					};
					temp.push(result);
				}
			}
			let uniq = unique(temp);
			setResults(uniq);
		})
		.catch((e) => {
			console.log("error getting org search", e);
		});
};


export const sendRejectionEmail = (org, email, onSuccess) => {
	functions.httpsCallable("rejectOrgVerification")({
		orgName: org.name,
		email
	})
		.then(() => {
			onSuccess();
		})
		.catch((e) => {
			console.log("error sending rejection email", e);
		});
}