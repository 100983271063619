import React, { useEffect, useState } from "react";
import {
	StyleSheet,
	View,
	ActivityIndicator,
	TouchableOpacity
} from "react-native";
import { COLORS } from "constants/Colors";
// import { Header } from "components/Header"
import { CustomInput } from "components/Inputs/CustomInput";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FiIcons from "react-icons/fi";
import { cloneDeep } from "lodash";
import { ConfirmModal } from "components/Modals/Confirmation";
import { EditUserCard } from "components/Cards/EditUserCard";
import { updateMemberInfo } from "services/user-service";
import { FullModal } from "components/Modals/FullModal";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
	getUsersName,
	validateEmail,
	validateEmailDoesntExist
} from "utils/helpers";
import { useOrganization } from "context/OrganizationContext";
import { ROLES } from "constants/Data";
import { RiAlertFill } from "react-icons/ri";
import { archiveUser, inviteMember } from "services/organizations-service";
import { useAuth } from "context/AuthContext";
import { functions } from "utils/firebase";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { CustomSwitch } from "components/Inputs/CustomSwitch";
import { useRayze } from "context/RayzeContext";

const options = [
	{ value: "active", label: "Active" },
	{ value: "archived", label: "Archived" }
];

function TabPanel(props) {
	const { children, value, index } = props;

	return <div>{value === index && <Box sx={{ p: 0 }}>{children}</Box>}</div>;
}

export function Team() {
	const { selectedOrg, members, setMembers, invitedMembers, userType } =
		useRayze();
	const { currentUser } = useAuth();

	const [value, setValue] = useState(0);
	const [search, setSearch] = useState("");
	const [selectedView, setSelectedView] = useState(options[0]?.value);
	const [ascending, setAscending] = useState(true);
	const [filtered, setFiltered] = useState([]);
	const [sorted, setSorted] = useState([]);

	//user add and edit
	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [showEditUser, setShowEditUser] = useState(false);
	const [showInviteUser, setShowInviteUser] = useState(false);
	const [inviteEmail, setInviteEmail] = useState("");
	const [inviteRole, setInviteRole] = useState(null);
	const [emailError, setEmailError] = useState(null);

	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const [submitDisabled, setSubmitDisabled] = useState(true);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!members) return;
		const membersMutate = members.map((x) => {
			return { uid: x.id };
		});
		const stringifiedMembers = JSON.stringify(membersMutate);
		functions
			.httpsCallable("getAuthUsers")(stringifiedMembers)
			.then((res) => {
				let temp = [];
				members.forEach((member) => {
					const find = res?.data.find((x) => x.uid === member.id);
					if (find) member.email = find.email;
					temp.push(member);
				});
				setUsers(temp);
			})
			.catch((e) => {
				console.log("error getting auth users", e);
			});
	}, [members, selectedOrg]);

	//on search change
	useEffect(() => {
		if (search) {
			const temp = users.filter(
				(x) =>
					x.firstName.toLowerCase().includes(search.toLowerCase()) ||
					x.lastName.toLowerCase().includes(search.toLowerCase())
			);

			setFiltered(temp);
		} else {
			setFiltered(users);
		}
	}, [search, users]);

	useEffect(() => {
		if (filtered) {
			let temp = cloneDeep(filtered);

			switch (selectedView) {
				case "active":
					temp = temp.filter((x) => x.active);
					break;
				case "archived":
					temp = temp.filter((x) => !x.active);
					break;
				default:
					break;
			}

			temp.sort((a, b) => sortObject(a.firstName, b.firstName));

			setSorted(temp);
		}
	}, [selectedView, ascending, filtered]);

	useEffect(() => {
		if (!showEditUser && !showConfirmModal) {
			setSelectedUser(null);
			setLoading(false);
		}
	}, [showEditUser, showConfirmModal]);

	useEffect(() => {
		setEmailError(null);
		setEmailError(
			!validateEmail(inviteEmail) ||
				validateEmailDoesntExist(inviteEmail, users)
		);
	}, [inviteEmail]);

	function sortObject(a, b) {
		if (a === b) return 0;

		if (!ascending) {
			return a < b ? 1 : -1;
		} else {
			return a < b ? -1 : 1;
		}
	}

	function onArchiveUser(user) {
		setSelectedUser(user);
		setShowConfirmModal(true);
	}

	function archive() {
		setLoading(true);
		archiveUser(
			selectedOrg.id,
			selectedUser.id,
			!selectedUser.active,
			selectedUser?.invite,
			onSuccessUpdate
		);
	}

	function inviteUser() {
		setLoading(true);
		//invite user via email
		inviteMember(selectedOrg, inviteRole, inviteEmail, onSuccessInvite);
	}

	function onSuccessInvite() {
		setShowInviteUser(false);
		setLoading(false);
		setInviteRole(null);
		setInviteEmail(null);
		window.alert("Invite successfully sent");
	}

	function editUser(user) {
		setSelectedUser(user);
		setShowEditUser(true);
	}

	function onChangeUserInfo(prop, value) {
		const temp = cloneDeep(selectedUser);
		temp[prop] = value;
		setSelectedUser(temp);
		updateMemberInfo(
			selectedOrg,
			selectedUser,
			{ [prop]: value },
			onSuccessUpdate
		);
	}

	useEffect(() => {
		setEmailError(null);

		if (showEditUser === "edit") {
			setSubmitDisabled(!selectedUser);
		} else {
			//adding user
			setSubmitDisabled(
				!validateEmail(selectedUser?.email) ||
					validateEmailDoesntExist(selectedUser?.email, users)
			);
		}
	}, [selectedUser]);

	function onSuccessUpdate() {
		setShowConfirmModal(false);
		setLoading(false);
	}

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			borderBottom: "1px solid lightgray",
			color: state.isSelected ? COLORS.primary.white : COLORS.primary.darkGray,
			paddingHorizontal: 12,
			fontFamily: "Montserrat",
			fontSize: 14
		}),
		valueContainer: (provided, state) => ({
			...provided,
			width: 115
		}),
		singleValue: (styles) => ({
			...styles,
			color: COLORS.primary.darkGray,
			fontFamily: "Montserrat",
			fontSize: 14
		})
	};

	return (
		<>
			<View style={styles.container}>
				{/** HEADER */}
				<Box
					display="flex"
					flexDirection={{ xs: "column", sm: "row" }}
					alignItems={{ sm: "center" }}
					justifyContent="space-between"
					marginBottom={12}
					gap={12}
				>
					<Box
						display="flex"
						flexDirection={{ xs: "column", sm: "row" }}
						width="100%"
						alignItems={{ sm: "center" }}
						gap={12}
					>
						{userType === "Leader" && (
							<TouchableOpacity
								style={styles.button}
								onPress={() => setShowInviteUser(true)}
							>
								<PrimaryText
									fontSize={14}
									fontWeight={800}
									style={{ color: COLORS.primary.white }}
								>
									Invite Team Member
								</PrimaryText>
								<FiIcons.FiUserPlus
									size={18}
									color={COLORS.primary.white}
								/>
							</TouchableOpacity>
						)}
						<CustomInput
							placeholder={"Search"}
							style={{ width: "100%", maxWidth: 400 }}
							onChangeText={setSearch}
							value={search}
						/>
					</Box>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							alignSelf: "flex-start"
						}}
					>
						<PrimaryText
							fontSize={14}
							fontWeight={800}
							style={{ color: COLORS.primary.darkGray, marginHorizontal: 4 }}
						>
							View
						</PrimaryText>

						<CustomPicker
							value={selectedView}
							onChange={(value) => setSelectedView(value)}
							options={options}
						/>
					</View>
				</Box>

				{/** TABLE */}
				<TableContainer>
					<Table
						className="table"
						style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
					>
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell style={{ textAlign: "left" }}>Name</TableCell>
								<TableCell>Role</TableCell>
								<TableCell>Email</TableCell>
								{userType === "Leader" && (
									<>
										<TableCell>Edit</TableCell>
										<TableCell>
											{selectedView.value === "active"
												? "Archive"
												: "Unarchive"}
										</TableCell>
									</>
								)}
							</TableRow>
						</TableHead>

						<TableBody>
							{sorted.concat(invitedMembers).map((item, index) => {
								return (
									<EditUserCard
										key={item?.id}
										item={item}
										onArchive={() => onArchiveUser(item)}
										onEdit={() => editUser(item)}
										isCurrentUser={currentUser?.id === item?.id}
										currentUserRole={userType}
									/>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>

				{/** EDIT USER */}
				{showEditUser && (
					<FullModal
						title={"Edit Team Member"}
						setShowModal={setShowEditUser}
						minHeight={500}
					>
						<View style={{ flex: 1 }}>
							<Tabs
								value={value}
								onChange={handleChange}
								sx={{
									"& .MuiTabs-indicator": {
										backgroundColor: COLORS.primary.lightBlue
									}
								}}
							>
								<Tab
									label="Permissions"
									style={{
										color: COLORS.primary.lightBlue,
										fontSize: 12,
										fontWeight: 700,
										fontFamily: "Montserrat"
									}}
								/>
								<Tab
									label="Notifications"
									style={{
										color: COLORS.primary.lightBlue,
										fontSize: 12,
										fontWeight: 700,
										fontFamily: "Montserrat"
									}}
								/>
							</Tabs>
							<View
								style={{
									height: 2,
									backgroundColor: COLORS.primary.lightGray,
									marginTop: -2,
									zIndex: -1
								}}
							/>

							{/** PERMISSIONS */}
							<TabPanel
								value={value}
								index={0}
							>
								<View style={{ flex: 1 }}>
									<PrimaryText
										fontSize={20}
										fontWeight={800}
										style={{
											textAlign: "center",
											marginTop: 12
										}}
									>
										{getUsersName(selectedUser)}
									</PrimaryText>
								</View>
								<View style={styles.settingsRow}>
									<PrimaryText
										fontSize={16}
										fontWeight={400}
										style={{ flex: 1 }}
									>
										Role
									</PrimaryText>
									<CustomPicker
										style={{ maxWidth: 170 }}
										value={selectedUser?.role}
										onChange={(value) => onChangeUserInfo("role", value)}
										options={ROLES}
									/>
								</View>

								{selectedOrg?.type === "nonprofit" && (
									<View style={styles.settingsRow}>
										<PrimaryText
											fontSize={16}
											fontWeight={400}
											style={styles.setting}
										>
											View Donations
										</PrimaryText>
										<CustomSwitch
											value={
												selectedUser?.viewDonations !== undefined
													? selectedUser?.viewDonations
													: true
											}
											onValueChange={(value) =>
												onChangeUserInfo("viewDonations", value)
											}
										/>
									</View>
								)}

								<View style={styles.settingsRow}>
									<PrimaryText
										fontSize={16}
										fontWeight={400}
										style={styles.setting}
									>
										Create Public Posts
									</PrimaryText>

									<CustomSwitch
										value={
											selectedUser?.publicPosts !== undefined
												? selectedUser?.publicPosts
												: true
										}
										onValueChange={(value) =>
											onChangeUserInfo("publicPosts", value)
										}
									/>
								</View>
								<View style={styles.settingsRow}>
									<PrimaryText
										fontSize={16}
										fontWeight={400}
										style={styles.setting}
									>
										Post New Events/Opportunities
									</PrimaryText>
									<CustomSwitch
										value={
											selectedUser?.postNewOpportunities !== undefined
												? selectedUser?.postNewOpportunities
												: true
										}
										onValueChange={(value) =>
											onChangeUserInfo("postNewOpportunities", value)
										}
									/>
								</View>
								<View style={styles.settingsRow}>
									<PrimaryText
										fontSize={16}
										fontWeight={400}
										style={styles.setting}
									>
										Volunteer Application Review
									</PrimaryText>
									<CustomSwitch
										value={
											selectedUser?.volunteerApplicationReview !== undefined
												? selectedUser?.volunteerApplicationReview
												: true
										}
										onValueChange={(value) =>
											onChangeUserInfo("volunteerApplicationReview", value)
										}
									/>
								</View>
								<View style={styles.settingsRow}>
									<PrimaryText
										fontSize={16}
										fontWeight={400}
										style={styles.setting}
									>
										Volunteer Check-in
									</PrimaryText>
									<CustomSwitch
										value={
											selectedUser?.volunteerCheckIn !== undefined
												? selectedUser?.volunteerCheckIn
												: true
										}
										onValueChange={(value) =>
											onChangeUserInfo("volunteerCheckIn", value)
										}
									/>
								</View>
							</TabPanel>

							{/** NOTIFICATIONS */}
							<TabPanel
								value={value}
								index={1}
							>
								<View style={{ flex: 1 }}>
									<PrimaryText
										fontSize={20}
										fontWeight={800}
										style={{
											textAlign: "center",
											marginTop: 12
										}}
									>
										{getUsersName(selectedUser)}
									</PrimaryText>
								</View>
								<View style={styles.settingsRow}>
									<PrimaryText
										fontSize={16}
										fontWeight={400}
										style={styles.setting}
									>
										New Application Received
									</PrimaryText>
									<CustomSwitch
										value={selectedUser?.receiveNewApplications}
										onValueChange={(value) =>
											onChangeUserInfo("receiveNewApplications", value)
										}
									/>
								</View>
								<View style={styles.settingsRow}>
									<PrimaryText
										fontSize={16}
										fontWeight={400}
										style={styles.setting}
									>
										Post Comments
									</PrimaryText>
									<CustomSwitch
										value={selectedUser?.receivePostComments}
										onValueChange={(value) =>
											onChangeUserInfo("receivePostComments", value)
										}
									/>
								</View>
							</TabPanel>
						</View>

						{/* <PrimaryText fontSize={16} fontWeight={500} >New Location Name</PrimaryText> */}
					</FullModal>
				)}

				{/** INVITE USER */}
				{showInviteUser && (
					<FullModal
						title={"Invite Team Member"}
						setShowModal={setShowInviteUser}
					>
						<View
							style={{
								flex: 1,
								minWidth: 500,
								backgroundColor: COLORS.primary.white
							}}
						>
							<View style={styles.settingsRow}>
								<PrimaryText
									fontSize={16}
									fontWeight={400}
									style={[styles.setting, { width: 50 }]}
								>
									Email:
								</PrimaryText>
								<CustomInput
									placeholder={"User's email address"}
									style={{ marginHorizontal: 12, minWidth: 350 }}
									onChangeText={setInviteEmail}
									value={inviteEmail}
								/>
							</View>
							{emailError && (
								<View style={styles.errorContainer}>
									<RiAlertFill
										width={16}
										height={16}
										fill={COLORS.primary.error}
										stroke={COLORS.primary.white}
									/>
									<PrimaryText
										fontSize={12}
										fontWeight={400}
										style={styles.errorMessage}
									>
										{validateEmailDoesntExist(inviteEmail, users)
											? "Email already exists"
											: "Email Invalid"}
									</PrimaryText>
								</View>
							)}

							<View style={[styles.settingsRow, { zIndex: 999 }]}>
								<PrimaryText
									fontSize={16}
									fontWeight={400}
									style={[styles.setting, { marginRight: 12, width: 50 }]}
								>
									Role:
								</PrimaryText>
								<CustomPicker
									styles={customStyles}
									value={inviteRole}
									onChange={(value) => setInviteRole(value)}
									options={ROLES}
								/>
							</View>

							<TouchableOpacity
								style={[
									styles.button,
									{
										opacity:
											emailError || !inviteEmail || !inviteRole ? 0.4 : 1,
										width: 200,
										alignSelf: "center",
										marginTop: 20
									}
								]}
								onPress={inviteUser}
								disabled={emailError || !inviteEmail || !inviteRole}
							>
								<PrimaryText
									fontSize={14}
									fontWeight={800}
									style={{ color: COLORS.primary.white }}
								>
									{"Send Invitation"}
								</PrimaryText>
							</TouchableOpacity>

							{/* <PrimaryText fontSize={16} fontWeight={500} >New Location Name</PrimaryText> */}
						</View>
					</FullModal>
				)}

				{showConfirmModal && (
					<ConfirmModal
						title={`${selectedUser?.active ? "Archive" : "Unarchive"} ${
							selectedUser?.firstName + " " + selectedUser?.lastName
						}`}
						message={"Are you sure?"}
						onConfirm={() => archive()}
						onCancel={() => setShowConfirmModal(false)}
					/>
				)}
			</View>
			{loading && (
				<ActivityIndicator
					animating={loading}
					size={"large"}
					style={styles.activity}
					color={COLORS.primary.lightBlue}
				/>
			)}
		</>
	);
}

const styles = StyleSheet.create({
	activity: {
		width: "100%",
		height: "100%",
		backgroundColor: COLORS.primary.white,
		position: "absolute",
		top: 0,
		bottom: 0
	},
	container: {
		flex: 1,
		width: "100%",
		flexDirection: "column",
		backgroundColor: COLORS.primary.white,
		paddingVertical: 12
	},
	button: {
		flexDirection: "row",
		backgroundColor: COLORS.primary.lightBlue,
		// height: 30,
		justifyContent: "center",
		alignItems: "center",
		paddingHorizontal: 20,
		paddingVertical: 6.5,
		borderRadius: 20
	},
	body: {
		flex: 1,
		width: "100%",
		backgroundColor: COLORS.primary.white,
		marginTop: 12,
		alignItems: "center"
	},

	settingsRow: {
		flexDirection: "row",
		paddingVertical: 8,
		paddingHorizontal: 20,
		alignItems: "center",
		justifyContent: "space-between",
		borderBottomColor: COLORS.primary.lightGray,
		borderBottomWidth: 0.5
	},
	setting: {},
	errorContainer: {
		backgroundColor: "#FEEFEF",
		height: 32,
		width: 350,
		justifyContent: "flex-start",
		alignItems: "center",
		paddingHorizontal: 16,
		paddingVertical: 6,
		borderRadius: 8,
		flexDirection: "row",
		margin: 12,
		marginLeft: 87
	},
	errorMessage: {
		color: COLORS.primary.error,
		paddingLeft: 8
	}
});
