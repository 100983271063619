import { firestore } from "utils/firebase";
import {
	collection,
	doc,
	endBefore,
	getAggregateFromServer,
	getCountFromServer,
	getDocs,
	limit,
	limitToLast,
	orderBy,
	query,
	startAfter,
	sum,
	where
} from "firebase/firestore";
import moment from "moment";

export const listenForRecurringDonationChanges = (
	org,
	setOurRecurringDonations
) => {
	return firestore
		.collection("donations_recurring")
		.where("orgId", "==", org.id)
		.where("active", "==", true)
		.orderBy("startDate", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let donations = []

				querySnapshot.forEach((doc) => {
					donations.push({
						id: doc.id,
						...doc.data(),
					})
				})

				setOurRecurringDonations(donations)
			},
			(e) => {
				console.log("listenForRecurringDonationChanges err", e)
			}
		)
}

export const getDonationHistoryByOrgId = async (
	currentUser,
	selectedDonation,
	orgId,
	setHistory,
	setLoading
) => {
	firestore()
		.collection("donations")
		.where("orgId", "==", orgId)
		.where("userId", "==", currentUser.id)
		.orderBy("date", "desc")
		.get()
		.then((querySnapshot) => {
			let history = []
			querySnapshot.forEach((doc) => {
				history.push({
					...doc.data(),
					id: doc.id,
					coverPhoto: selectedDonation?.coverPhoto,
					organization: selectedDonation?.organization,
				})
			})
			setHistory(history)
			setLoading(false)
		})
		.catch((e) => {
			setLoading(false)
			console.log("getDonationHistoryByOrgId error", e)
		})
}

export const getRecentDonations = async (
	setHistory,
	nonprofits
) => {
	console.log('get em')
	firestore
		.collection("donations")
		.orderBy("date", "desc")
		// .limit(10)
		.get()
		.then((querySnapshot) => {
			let history = []
			querySnapshot.forEach((doc) => {
				history.push({
					...doc.data(),
					id: doc.id,
				})
				//find matching nonprofit
				let org = nonprofits.find((np) => np.id === doc.data().orgId)
				if (org) {
					history[history.length - 1].org = org
				}
			})
			setHistory(history)
		})
		.catch((e) => {
			console.log("getRecentDonations error", e)
		})
}

export const getSelectedUserDonations = async ({
	userId,
	donations = [],
	rowsPerPage,
	currentPage,
	donationsCount,
	nextPage
}) => {
	const coll = collection(firestore, "donations");

	const queries = [];

	// get for specific user
	queries.push(where("userId", "==", userId));

	// get total no. of donations after filters
	const countQuery = query(coll, ...queries);
	const totalCount = await getCountFromServer(countQuery);

	// Where to go next, previous, first or last
	const go =
		nextPage === 0
			? "first"
			: donationsCount && nextPage === Math.ceil(donationsCount / rowsPerPage)
				? "last"
				: nextPage > currentPage
					? "next"
					: nextPage < currentPage
						? "previous"
						: "first";

	// Define the rows limit
	const rowsLimit = go === "last" ? donationsCount % rowsPerPage : rowsPerPage;

	// Sorting
	queries.push(orderBy("date", "desc"));

	// Pagination queries
	if (go === "first") queries.push(limit(rowsLimit || 10));

	if (go === "last") queries.push(limitToLast(rowsLimit || 10));

	if (go === "next" && donations.length)
		queries.push(
			startAfter(moment(donations[donations.length - 1]?.date).toDate()),
			limit(rowsLimit || 10)
		);

	if (go === "previous" && donations.length)
		queries.push(
			endBefore(moment(donations[0]?.date).toDate()),
			limitToLast(rowsLimit || 10)
		);

	// get data query
	const dataQuery = query(coll, ...queries);
	const snapshot = await getDocs(dataQuery);

	if (snapshot.empty) throw new Error("No donations found");

	const newDonations = snapshot.docs.map((doc) => {
		const { recurring, amount, date, appClip } = doc.data();

		return {
			id: doc.id,
			...doc.data(),
			source: appClip ? "Website" : "App",
			frequency: recurring ? "recurring" : "one-time",
			amount,
			date: moment(date.toDate()).format("MM/DD/YYYY"),
		};
	});

	// get total donations by a user to the org
	const sumQuery = query(coll, where("userId", "==", userId));
	const totalDonation = await getAggregateFromServer(sumQuery, {
		totalDonation: sum("amount")
	});

	const results = {
		donationsCount: totalCount.data().count,
		donations: newDonations,
		totalDonation: totalDonation.data().totalDonation
	};

	return results;
};
