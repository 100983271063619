import React, { useEffect, useState } from "react";
import { Text, StyleSheet, Dimensions } from "react-native";
import { COLORS } from "constants/Colors";

const WIDTH_OF_DESIGN_SCREEN = 1400;

export const PrimaryText = (props) => {
	const { fontSize, fontWeight, style: customStyles, numberOfLines } = props;
	const [width, setWidth] = useState(
		Dimensions.get("window").width < 1400
			? Dimensions.get("window").width
			: 1400
	);
	const computedFontSize = (fontSize / WIDTH_OF_DESIGN_SCREEN) * width;

	useEffect(() => {
		const handleChange = ({ screen, window: win }) => {
			win.width > 1024 && win.width < 1400 && setWidth(win.width);
		};

		const subscription = Dimensions.addEventListener("change", handleChange);
		return () => {
			subscription.remove();
		};
	}, [setWidth]);

	const style = styles(computedFontSize, fontWeight, width);

	return (
		<Text
			style={[style.title, customStyles]}
			numberOfLines={numberOfLines ? numberOfLines : 0}
		>
			{props.children}
		</Text>
	);
};

const styles = (fontSize, fontWeight, screenWidth) =>
	StyleSheet.create({
		title: {
			fontSize: fontSize < 12 ? 12 : fontSize,
			lineHeight: (fontSize < 12 ? 12 : fontSize) * 1.25,
			color: COLORS.primary.darkBlue,
			fontFamily: "Montserrat",
			fontWeight: fontWeight,
			textAlign: "left"
		}
	});
