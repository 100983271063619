import React, { useEffect, useState } from "react"
import { PrimaryText } from "components/Common/PrimaryText"
import { ProfilePic } from "components/Pics/ProfilePic"
import { TableCell, TableRow } from "@mui/material"
import { getUser } from "services/user-service"
import { dollarFormatter, getUsersName } from "utils/helpers"
import moment from "moment"

export const DonationCard = ({ item, onPress }) => {
  const { userId, amount, fee = 0, date, recurring, org, anonymous = false } = item

	const [user, setUser] = useState(item?.user);

	useEffect(() => {
		if (!user) {
			// console.log('no user')
			userId && getUser(userId, setUser);
		}
	}, [userId]);

  return (
    <TableRow 
    hover={userId ? true : false}
    onClick={() => {
      console.log('donation clicked', item)
      onPress()
    }}>
      <TableCell width={36}>
        <ProfilePic uri={user?.profilePic} userId={userId} size={36} />
      </TableCell>
      <TableCell  style={{ textAlign: "left" }}>
        <PrimaryText fontSize={16} fontWeight={400}>
          {org?.name}
        </PrimaryText>
      </TableCell>
      <TableCell  style={{ textAlign: "left" }}>
        <PrimaryText fontSize={16} fontWeight={400}>
          {user ? getUsersName(user) : (anonymous ? "Anonymous" : item?.name)}
        </PrimaryText>
      </TableCell>
      <TableCell  style={{ textAlign: "left" }}>
        <PrimaryText fontSize={16} fontWeight={500}>
          {anonymous ? "" : (item?.email || user?.email)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {recurring ? "recurring" : "one-time"}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {dollarFormatter.format(amount-fee)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {dollarFormatter.format(fee)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {dollarFormatter.format(amount)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {moment(date.toDate()).format("MM/DD/YYYY")}
        </PrimaryText>
      </TableCell>
    </TableRow>
  )
}
