import React, { useState, useContext, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import FormInput from "components/Partials/FormInput";
import { UserContext } from "context/UserContext";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import { COLORS } from "constants/Colors";
import { UserCard } from "components/Cards/UserCard";
import { PrimaryText } from "components/Common/PrimaryText";
import { useDashboard } from "context/DashboardContext";
import { useCauses } from "context/CausesContext";
import { CauseSkillsCard } from "components/Cards/CauseSkillsCard";
import { TouchableOpacity } from "react-native-web";
import * as FaIcons from "react-icons/fa";
import { useSkills } from "context/SkillsContext";

export const Skills = (props) => {
	const { users, setGetUsers } = useContext(UserContext);
	const { allSkills } = useSkills();
	const { rayzeStats } = useDashboard();

	useEffect(() => {
		setGetUsers(true);
	}, []);

	// let data = []
	// users.map((el) => {
	//   data.push({
	//     // id: el.id,
	//     Name: el.name,
	//     City: el.city,
	//     State: el.state,
	//     Zip: el.zipCode,
	//     Phone: el.phone,
	//     Email: el.email,
	//     Gender: el.gender,
	//     // Age: getAgeFromBirthday(new Date(el.birthday.seconds * 1000).getTime()),
	//     Age: "2022",
	//     // JoinDate: new Date(el.createdAt.seconds * 1000).toLocaleDateString(),
	//     JoinDate: "2-00",
	//     Status: el.status,
	//   })
	// })

	// const csvReport = {
	//   filename: "Report.csv",
	//   // headers,
	//   data,
	// }

	const [search, setSearch] = useState("");

	return (
		<View style={styles.tableContainer}>
			{/* <div className="main-content manageAdmin"> */}

			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center"
				}}
			>
				<PrimaryText
					fontSize={24}
					fontWeight={700}
					style={styles.header}
				>
					Skills
				</PrimaryText>

				<TouchableOpacity
					style={styles.button}
					onPress={null}
				>
					<PrimaryText
						fontSize={14}
						fontWeight={300}
						style={{ color: COLORS.primary.white, marginRight: 12 }}
					>
						Add New Skill
					</PrimaryText>
					<FaIcons.FaPlus
						size={16}
						color={COLORS.primary.white}
					/>
				</TouchableOpacity>
			</View>

			<PrimaryText
				fontSize={14}
				fontWeight={400}
				style={styles.subtitle}
			>
				{`Showing ${allSkills?.length} Active Skills`}
			</PrimaryText>

			<TableContainer>
				<Table
					className="table"
					style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
				>
					<TableHead>
						<TableRow>
							<TableCell>Skill</TableCell>
							<TableCell>Users</TableCell>
							<TableCell>Organizations</TableCell>
							<TableCell>Events</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{allSkills &&
							allSkills.map((item, i) => {
								return (
									<CauseSkillsCard
										item={item}
										key={i}
									/>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
		</View>
	);
};

const styles = StyleSheet.create({
	tableContainer: {
		width: "100%",
		// backgroundColor: COLORS.primary.offWhite,
		borderRadius: 12,
		borderTopLeftRadius: 0,
		paddingVertical: 12
	},
	header: {
		color: COLORS.primary.darkBlue
	},
	subtitle: {
		color: COLORS.primary.darkBlue,
		marginTop: 12
	},
	button: {
		flexDirection: "row",
		backgroundColor: COLORS.primary.lightBlue,
		width: 200,
		justifyContent: "center",
		alignItems: "center",
		padding: 8,
		borderRadius: 6
	}
});
