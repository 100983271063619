import React, { createContext, useState, useEffect, useContext } from "react";
import { fetchMoreUsers } from "services/user-service";
import { useCauses } from "./CausesContext";
import { getOurCauses } from "services/causes-service";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const { allCauses } = useCauses();
	const [lastDoc, setLastDoc] = useState(null);
	const [users, setUsers] = useState([]);
	const [getUsers, setGetUsers] = useState(false);
	const [page, setPage] = useState(1);

	const [selectedUser, setSelectedUser] = useState(null);
	const [causes, setCauses] = useState(null);
	const [skills, setSkills] = useState(null);
	const [sortedBy, setSortedBy] = useState();

	useEffect(() => {
		if (getUsers) {
			fetchMoreUsers(lastDoc, setLastDoc, users, setUsers, sortedBy);
		}
	}, [getUsers, sortedBy]);

	function nextPage() {
		fetchMoreUsers(lastDoc, setLastDoc, users, setUsers, sortedBy);
		setPage(page + 1);
	}

	useEffect(() => {
		console.log(sortedBy);
		if (sortedBy?.sortOrder && sortedBy?.sortBy) {
			fetchMoreUsers(null, setLastDoc, [], setUsers, sortedBy);
			setPage(1);
		}
	}, [sortedBy]);

	//set selected user causes
	useEffect(() => {
		if (!selectedUser || !allCauses) return;
		getOurCauses(selectedUser, allCauses, setCauses);
	}, [selectedUser, allCauses]);

	const handleSort = (sortBy) => {
		setSortedBy({
			sortBy,
			sortOrder: sortedBy?.sortOrder === "desc" ? "asc" : "desc"
		});
	};

	return (
		<UserContext.Provider
			value={{
				nextPage,
				users,
				setUsers,
				setGetUsers,
				selectedUser,
				setSelectedUser,
				causes,
				skills,
				page,
				setPage,
				sortedBy,
				handleSort
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export const useUsers = () => useContext(UserContext);
