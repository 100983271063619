import { useWindowDimensions } from "react-native-web";

const breakPoints = {
	bp_xs: 0,
	bp_sm: 600,
	bp_md: 900,
	bp_lg: 1200,
	bp_xl: 1536
};

export default function useMediaQuery() {
	const { width } = useWindowDimensions();
	const { bp_sm, bp_md, bp_lg, bp_xl } = breakPoints;

	const getResponsiveValue = ({ xs = 0, sm = 0, md = 0, lg = 0, xl = 0 }) =>
		width > bp_xl && xl
			? xl
			: width > bp_lg && lg
			? lg
			: width > bp_md && md
			? md
			: width > bp_sm && sm
			? sm
			: xs;

	return {
		getResponsiveValue
	};
}
