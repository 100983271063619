// import Sidebar from "components/Partials/Sidebar"
// import Topnav from "components/Partials/Topnav"
import React, { useState, useContext, useEffect } from "react";
// import ActivityFeedTable from "components/Partials/ActivityFeedTable"
// import UserDetailGroupTable from "components/Partials/UserDetailGroupTable"
// import UserDetailEventsTable from "components/Partials/UserDetailEventsTable"
// import UserDetailDonation from "components/Partials/UserDetailDonation"
// import UserDetailReports from "components/Partials/UserDetailReports"
import { Header } from "components/Header";
import { COLORS } from "constants/Colors";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserContext";
import { firestore } from "utils/firebase";
import DeleteAdminModal from "components/Partials/modals/DeleteAdminModal";
import AdminDeletedModal from "components/Partials/modals/AdminDeletedModal";
import EmailSentModal from "components/Partials/modals/EmailSentModal";
// import { getDashboardReportsList } from "firebase/services"
import { capitalize, formattedDate, formattedPhoneNumber } from "utils";
import {
	Dimensions,
	ScrollView,
	StyleSheet,
	TouchableOpacity,
	View
} from "react-native-web";
import { ProfilePic } from "components/Pics/ProfilePic";
import { PrimaryText } from "components/Common/PrimaryText";
import { getUsersName } from "utils/helpers";
import { ConfirmModal } from "components/Modals/Confirmation";
import { deleteUser } from "services/user-service";
import { Tabs } from "components/Tabs/Tabs";
import moment from "moment";
import { Box } from "@mui/material";

const { height } = Dimensions.get("window");
const tabs = [
	"Activity Feed",
	"Organizations",
	"Actions",
	"Donations",
	"Reports"
];

export const UserDetail = (props) => {
	const navigate = useNavigate();
	const { selectedUser, users, causes, skills } = useContext(UserContext);

	const [activeTab, setActiveTab] = useState("Activity Feed");
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const statistics = [
		[
			{
				title: "Inspiring",
				amount: selectedUser?.followers || 0
			}
		],
		[
			{
				title: "Inspirations",
				amount: selectedUser?.following || 0
			}
		]
	];

	const userDetails = [
		{ title: "Email", value: selectedUser.email },
		{ title: "Phone", value: formattedPhoneNumber(selectedUser.phone) },
		{ title: "Gender", value: capitalize(selectedUser.gender) },
		{
			title: "Location",
			value: selectedUser?.city
				? selectedUser?.city + ", " + selectedUser?.state
				: selectedUser?.zipCode
		},
		{
			title: "Birthday",
			value: moment(selectedUser?.birthday.toDate()).format("MMMM, YYYY")
		},
		{ title: "Join Date", value: formattedDate(selectedUser.createdAt) }
	];

	const userDetails2 = [
		{ title: "Comments", value: "##" },
		{ title: "Likes/lumens", value: "##" }
	];

	function onDeleteUser() {
		const findIndex = users.findIndex((x) => x.id === selectedUser.id);
		users.splice(findIndex, 1);

		deleteUser(selectedUser, () => navigate(-1));
	}

	return (
		<ScrollView style={{ width: "100%", maxHeight: height - 80 }}>
			<View style={styles.container}>
				<Header
					title={getUsersName(selectedUser) + " - @" + selectedUser?.username}
					onBack={() => navigate(-1)}
					onRight={() => setShowConfirmModal(true)}
					rightText={"Delete Account"}
					passwordReset={selectedUser}
					// exportData={formatMostValuableItemsReport(filtered)}
				/>
				{/** ROW 1 */}
				<Box
					maxHeight={300}
					display="flex"
					flexDirection={{ xs: "column", sm: "row" }}
					alignItems="center"
					mt={24}
					mb={16}
					gap={16}
				>
					<ProfilePic
						uri={selectedUser?.profilePic}
						size={100}
					/>
					<Box
						display="flex"
						flex={1}
						flexDirection="row"
						padding={27}
						justifyContent="space-around"
						backgroundColor={COLORS.primary.offWhite}
						borderRadius={3}
						width="100%"
					>
						{statistics.map((row, i) => {
							return (
								<View
									style={{ justifyContent: "space-between" }}
									key={i}
								>
									{row.map((item, i) => {
										const { title, amount } = item;
										return (
											<View
												style={styles.statContainer}
												key={i}
											>
												<PrimaryText
													fontSize={18}
													fontWeight={400}
													style={styles.header}
												>
													{title}
												</PrimaryText>
												<PrimaryText
													fontSize={24}
													fontWeight={700}
													style={styles.header}
												>
													{amount}
												</PrimaryText>
											</View>
										);
									})}
								</View>
							);
						})}
					</Box>
				</Box>

				{/** ROW 2 */}
				<Box
					display="flex"
					flexDirection={{ xs: "column", sm: "row" }}
					marginBottom={16}
					maxHeight={300}
					gap={16}
				>
					<View style={styles.infoContainer}>
						{userDetails.map((u, i) => {
							const { title, value } = u;
							if (!value) return null;
							return (
								<View
									style={styles.infoTextRow}
									key={i}
								>
									<PrimaryText
										fontSize={14}
										fontWeight={400}
										style={{
											flex: 1,
											textAlign: "right",
											marginRight: 12,
											maxWidth: 180
										}}
									>
										{title}
									</PrimaryText>

									<PrimaryText
										fontSize={16}
										fontWeight={600}
										style={u?.capitalize && { textTransform: "capitalize" }}
									>
										{value}
									</PrimaryText>
								</View>
							);
						})}
					</View>

					<ScrollView style={styles.causesContainer}>
						<PrimaryText
							fontSize={16}
							fontWeight={600}
						>
							{"Causes"}
						</PrimaryText>
						<View style={styles.causes}>
							{causes &&
								causes.map((item) => (
									<View
										style={styles.cause}
										key={item.id}
									>
										<PrimaryText
											fontSize={14}
											fontWeight={400}
											style={{ color: COLORS.primary.lightBlue }}
										>
											{item.name}
										</PrimaryText>
										{/* <FaIcons.FaTimes /> */}
									</View>
								))}
						</View>
					</ScrollView>
				</Box>
			</View>
			<Tabs
				tabs={tabs}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
			/>

			{showConfirmModal && (
				<ConfirmModal
					title={"Delete User"}
					message={"Are you sure?"}
					onConfirm={onDeleteUser}
					onCancel={() => setShowConfirmModal(false)}
				/>
			)}
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	activity: {
		flex: 1,
		width: "100%",
		backgroundColor: COLORS.primary.white
	},
	container: {
		flex: 1,
		width: "100%",
		paddingVertical: 20,
		backgroundColor: COLORS.primary.white
	},
	header: {
		color: COLORS.primary.darkBlue
	},
	cover_img_v: {
		width: "100%",
		aspectRatio: 16 / 9
	},
	statsPhoto: {
		width: "35%"
	},
	statContainer: {
		alignItems: "center"
	},

	infoContainer: {
		flex: 1,
		padding: 20,
		justifyContent: "space-around",
		backgroundColor: COLORS.primary.offWhite,
		borderRadius: 12
	},
	infoTextRow: {
		flexDirection: "row",
		alignItems: "center",
		marginVertical: 6
	},
	causesContainer: {
		flex: 1,
		// height: 200,
		padding: 20,
		backgroundColor: COLORS.primary.offWhite,
		borderRadius: 12
	},
	causes: {
		flex: 1,
		flexDirection: "row",
		flexWrap: "wrap",
		paddingBottom: 20,
		paddingTop: 12
	},
	cause: {
		flexDirection: "row",
		alignItems: "center",
		paddingVertical: 4,
		paddingHorizontal: 8,
		marginRight: 8,
		marginBottom: 10,
		borderRadius: 4,
		borderWidth: 1,
		borderColor: COLORS.primary.lightBlue
	},
	verificationStatusContainer: {
		flex: 1,
		flexDirection: "row",
		paddingBottom: 20,
		paddingTop: 12
	},
	tabs: {
		flexDirection: "row",
		alignItems: "flex-start"
	},
	tabsButton: {
		width: 168,
		height: 36,
		backgroundColor: COLORS.primary.gray,
		borderTopLeftRadius: 12,
		borderTopRightRadius: 12,
		marginRight: 12,
		alignItems: "center",
		justifyContent: "center"
	},
	tabActive: {
		backgroundColor: COLORS.primary.offWhite,
		color: "black"
	},
	tabText: {
		textAlign: "center",
		color: COLORS.primary.white,
		fontWeight: "500"
	},
	tabTextActive: {
		color: COLORS.primary.darkBlue,
		fontWeight: "700"
	},
	button: {
		flexDirection: "row",
		backgroundColor: COLORS.primary.lightBlue,
		width: 200,
		justifyContent: "center",
		alignItems: "center",
		padding: 8,
		// margin: 12,
		borderRadius: 6
	},
	saveButton: {
		flexDirection: "row",
		backgroundColor: COLORS.primary.green,
		// width: 200,
		justifyContent: "center",
		alignItems: "center",
		padding: 8,
		margin: 12,
		borderRadius: 6
	}
});
